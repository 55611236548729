import React from 'react'
import { AppSettings } from '../../config/app-settings.js'

import { me } from './../../store/api.js';

import InputTextMask    from '../../components/atoms/InputTextMask'
import InputText    from '../../components/atoms/InputText'
import Label        from '../../components/atoms/Label'
import Cards 		from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

export default class Cartao extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this.state = {
            userData: null,
            hasLogin: this.props.noLogin ? false : true,
            criaCartao:0,
            cards:[],
            card:{
				cvc: '',
				expiry: '',
				focus:'number',
				name: '',
				number: '',
				token: '',
				cpf:'',
                hasLogin: this.props.noLogin ? false : true
			},
        }

        this.handlerCard = this.handlerCard.bind(this)
    }

    async handlerCard(name, value){
		let card = this.state.card

		card[name] = value
		card['focus'] = name

		this.setState({ card })
	}

    async componentDidMount() {
        if (this.state.hasLogin) {
            const token = JSON.parse(localStorage.getItem('cliente'))
            const usuario = await me({items: {token: token}})
            this.setState({userData:usuario.data })

            if (usuario.data.usuario) {
                const dataUser = usuario.data.usuario

                if (dataUser && dataUser.cards && dataUser.cards.length > 0 ){
                        this.setState({cards: dataUser.cards })
                }
            }
        }
    }

    async selectCard(item){
		this.props.submitCard( item )
	}

    submitCard(){
        this.props.submitCard(this.state.card)
    }

    render() {
        const cpf = this.state.userData && this.state.hasLogin ? this.state.userData.usuario.cpf : null

        return (
            <div>
                {this.state.hasLogin && (
                    <ul className="nav nav-pills mb-3">
                        <li className="nav-item">
                            <a href="#default-tab-1" className={this.state.criaCartao === 0 ? "nav-link active" : "nav-link"} onClick={(e) => {e.preventDefault(); this.setState({criaCartao: 0})} }>Novo Cartão</a>
                        </li>
                        <li className="nav-item">
                            <a href="#default-tab-1" className={this.state.criaCartao === 1 ? "nav-link active" : "nav-link"} onClick={(e) => {e.preventDefault(); this.setState({criaCartao: 1})} }>Meus cartões</a>
                        </li>
                    </ul>
                )}
                
                {this.state.criaCartao === 0 && (
                    <div>
                        <div className='mb-3'>
                            {this.state.card && (
                                <Cards
                                    cvc={this.state.card.cvc}
                                    expiry={this.state.card.expiry}
                                    focused={this.state.card.focus}
                                    name={this.state.card.name}
                                    number={this.state.card.number}
                                />
                            )}
                        </div>
                        
                        <div className="form-floating mb-3">
                            <InputTextMask nome="number" mask={"9999 9999 9999 9999"}  setFomrData={this.handlerCard} dadosIniciais={this.state.card ? this.state.card.number : ''} />
                            <Label nome="number" label="Numero do cartão de crédito"/>
                        </div>

                        <div className="form-floating mb-3">
                            <InputText nome="name" setFomrData={this.handlerCard} dadosIniciais={this.state.card ? this.state.card.name : ''} />
                            <Label nome="name" label="Nome do titular"/>
                        </div>

                        <div className='row'>
                            <div className="form-floating mb-3 col-6">
                                <InputTextMask nome="cvc" mask={"999"} setFomrData={this.handlerCard} dadosIniciais={this.state.card ? this.state.card.cvc : ''} />
                                <Label nome="cvc" label="CVV"/>
                            </div>

                            <div className="form-floating mb-3 col-6">
                                <InputTextMask nome="expiry" mask={"99/99"}  setFomrData={this.handlerCard} dadosIniciais={this.state.card ? this.state.card.expiry : ''} />
                                <Label nome="expiry" label="Validade"/>
                            </div>
                        </div>

                        <div className="form-floating mb-3">
                            <InputTextMask nome="cpf" mask={"999.999.999-99"}  setFomrData={this.handlerCard} dadosIniciais={cpf} />
                            <Label nome="cpf" label="CPF do titular"/>
                        </div>

                        {this.state.hasLogin ? (
                            <button className="btn btn-primary   btn-lg " style={{width: "100%"}} onClick={() => {this.submitCard()}}>
                                Salvar Cartão
                            </button>
                        ) : (
                            <>
                                <button className="btn btn-primary   btn-lg " style={{width: "100%"}} onClick={() => {this.submitCard()}}>
                                    Continuar
                                </button>
                                <div class="alert alert-success fade show mt-3" style={{textAlign:"center"}}>
                                    Não armazenamos as informações do seu cartão.
                                </div>
                            </>
                        )}
                    </div>
                )}

                {this.state.criaCartao === 1 && (
                    <div>
                        {this.state.cards && this.state.cards.map((i) => {
                            return (
                                <div className='col-12 mt-3' onClick={() => this.selectCard(i)}  style={{border:"1px solid #dee2e6", borderRadius: "10px", padding:"15px", boxShadow:"3px", "boxShadow":"0 0.5rem 1rem rgba(0, 0, 0, 0.095)",}}>
                                    <div className='col-2' style={{ display:"inline-block"}}>
                                        {this.state.card.creditCardNumber === i.creditCardNumber ? (
                                            <div style={{  marginTop: "-27px", border:"1px solid #0078FF", height: "15px", width: "15px", backgroundColor: "#0078FF", borderRadius: "50%"}}></div>
                                        ) : (
                                            <div style={{ marginTop: "-27px",  border:"1px solid #000", height: "15px", width: "15px", borderRadius:"50%"}}></div>
                                        )}
                                    </div>

                                    
                                    <div className="col-10"  style={{display:"inline-block", position:"relative"}}>
                                        <i className="col-5 fa-solid fa-credit-card" style={{color:"#0078FF", fontSize:"22px", position:"absolute"}}></i>
                                        <div style={{marginLeft: "30px"}}>
                                            <p style={{margin: "0", fontSize: "14px"}}>****** <strong>{i.creditCardNumber}</strong></p>
                                            <span className='badge bg-light rounded-pill' style={{color:"#0078FF"}}>PADRÃO</span>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}
                    </div>
                )}
            </div>
        )
    }
}