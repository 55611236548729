import React from 'react'

import Lottie from "lottie-react"
import { Modal, ModalBody } from 'reactstrap'
import { saveNomeComanda } from '../../store/api.js';
import { AppSettings } from '../../config/app-settings.js'
import Label        from '../atoms/Label.jsx'



import recuperaSessao from '../../assets/abrir-mesa.json'

export default class Pager extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
		super(props)

		this.state = {
            brandData:null,
            dataUser:{idComanda:null},
            formError:false
		}

        this.setUserData = this.setUserData.bind(this)
        this.addNomeComanda = this.addNomeComanda.bind(this)
	}

    async componentDidMount() {
        this.setState({brandData:this.context.brand, dataUser:{ }})
	}

    async setUserData(nome, valor){
		let userData = this.state.dataUser
		userData[nome] = valor
		this.setState({dataUser: userData})
	}

    async addNomeComanda(){
		const data = this.state.dataUser
        data.idComanda =this.context.comanda.id

        if (data.nome && data.mesa){
            const comanda = await saveNomeComanda(data)

            if (comanda.data.id && comanda.data.nome_cliente && comanda.data.mesa_cliente){
                this.context.handleSetPager(false)
                this.context.handleSetComanda(comanda.data)
            }

            localStorage.setItem('comanda', JSON.stringify(comanda))
        } else {
            this.setState({formError: true})
        }
	}

    render() {
        const brand = this.context.brand
        const comanda = this.context.comanda
        const img = brand && brand.loja.imagem && brand.loja.imagem.imagem ? brand.loja.imagem.imagem : brand && brand.imagem_perfil ? brand.imagem_perfil.imagem : ""

		return (
            <Modal isOpen={this.context.pager} >
                <ModalBody className="p-5" style={{textAlign: "center"}}>
                    

                    {comanda && comanda.nome_cliente && comanda.mesa_cliente ? (
                         <>
                            <Lottie animationData={recuperaSessao} loop={true} style={{width: "50%", margin:"auto"}} />
                            <h3>Gostaria de retomar o pedido <br></br>em andamento ou criar um novo?</h3><br></br>
                            <button className="btn btn-primary btn-lg me-1 mb-15px" style={{width: "60%", marginBottom:"50px",}} onClick={() => {this.context.handleSetPager(false)}}>
                                Retomar pedido
                            </button>
                            <div to="/" onClick={(e) => {localStorage.clear(); window.location.reload(true)}} style={{fontSize: "larger", color:"#0078ff", textDecoration: "none","cursor":"pointer", textDecoration: "none", fontWeight: "600"}}>
                                Criar um novo
                            </div>
                        </> 
                    ) : (
                        <>
                            <div className="row" style={{height: "80px", maxWidth: "180px", position: "relative", margin: "auto"}}>
                                <img alt="logo da grub" src="https://grubicons.s3.us-east-1.amazonaws.com/logo-grub.png" style={{width: "80px", borderRadius: "50%", position: "absolute", left: "15%"}} /> 
                                <img alt="logo da marca" src={"https://grubupload.s3.amazonaws.com/"+img} style={{width: "80px", borderRadius: "50%", position: "absolute", right: "15%"}} /> 
                            </div>

                            <h3>Digite seu nome e sua mesa <br></br>para efetuar o pedido.</h3>

                            <div className="form-floating mb-15px">
                                <input maxLength={10} type="text" className="form-control fs-15px" name="nome" autoComplete={'off'} required onChange={(e) => this.setUserData('nome', e.target.value.toUpperCase())} style={{textTransform: 'uppercase'}} />
                                <Label nome="nome" label="Nome"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <input maxLength={10} type="number" className="form-control fs-15px" name="mesa" autoComplete={'off'} required onChange={(e) => this.setUserData('mesa', e.target.value.toUpperCase())} style={{textTransform: 'uppercase'}} />
                                <Label nome="nome" label="Mesa"/>
                            </div>

                            {this.state.formError && (
                                <div className="alert alert-danger" role="alert">
                                    Preencha todos os campos para efetuar o pedido.
                                </div>
                            )}

                            <button className="btn btn-primary btn-lg me-1 mb-1" style={{width: "100%"}} onClick={() => {this.addNomeComanda(this.context.comanda.id)}}>
                                Continuar
                            </button>
                        </>
                    )}
                    
                    <div className="row">
                        <img 
                            alt="logo quik"
                            src="https://grubicons.s3.us-east-1.amazonaws.com/quick-grub.svg"
                            style={{
                                width: "80px",
                                margin: "30px auto 0 auto"
                            }}
                        />
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}