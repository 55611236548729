import React from 'react'
import { AppSettings } from '../../config/app-settings.js'

import { Link } from 'react-router-dom'
import Carrinho from './carrinho-dinein.jsx'

import Lottie from "lottie-react"
import lottiejson from "../../assets/pedido-enviado-cardapio.json"
import lottiejsonErro from "../../assets/cartao-erro.json"
import lottieHistoricoPedido from "../../assets/delivery-espera.json"

import { getHistoricoCarrinho } from '../../store/api.js';

export default class SideBarDinein extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
        super(props)
        
        this.state = {
            abaHistorico:this.props.showHistorico ? this.props.showHistorico : false,
            historico:[],
            pedidoEnviado: false
        }

        this.handleAbaPedidos = this.handleAbaPedidos.bind(this)
        this.componentDidMount = this.componentDidMount.bind(this)
        this.atualizaStatus = this.atualizaStatus.bind(this)
    }

    async handleAbaPedidos(){
        const comanda = this.context.historicoCarrinho
        const historico = await getHistoricoCarrinho(comanda.id)
        this.setState({historico: historico.data})
		this.setState({abaHistorico: !this.state.abaHistorico})
	}

    async componentDidMount(){
        const comanda = this.context.comanda

        if (comanda) {
            const historico = await getHistoricoCarrinho(comanda.id)
            this.setState({historico: historico.data})
            this.props.loading(false)
        }
    }

    async atualizaStatus(){
		this.setState({ pedidoEnviado: true})
	}

    mudaQuantidadeCarrinho(novoValor, index){
		const items = this.context.itensCarrinho

		if(novoValor === 'add') {
			items[index].quantidade = items[index].quantidade + 1 
		} else {
			items[index].quantidade = items[index].quantidade - 1 

			if (items[index].quantidade === 0 ) {
				items.splice(index, 1)
			}
		}

		this.context.atualizaCarrinho( items )
	}

    render() {
        const produtosHistorico = this.state.historicoCarrinho ? this.context.historicoCarrinho : this.state.historico
        const itensCarrinho = this.context.itensCarrinho ? this.context.itensCarrinho : []

        return (
            <div className="pos-sidebar" style={{height: "90%"}}>
                <div className="pos-sidebar-header" style={{backgroundColor: "#0078FF"}}>
                    {this.context.comanda && (
                        <div className="title">
                            {'Comanda '+ this.context.comanda.id }
                        </div>
                    )}
                </div>

                <div className="pos-sidebar-nav">
                    <ul className="nav nav-tabs nav-fill">
                        <li className="nav-item">
                            <Link to="novo_pedido" onClick={(event) => { event.preventDefault(); this.handleAbaPedidos()}} className={this.state.abaHistorico ? "nav-link" : "nav-link active"}>
                                Carrinho ({itensCarrinho.length})
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="historico_pedido" onClick={(event) => { event.preventDefault(); this.handleAbaPedidos()}} className={this.state.abaHistorico ? "nav-link active" : "nav-link"}>
                                Meus pedidos ({produtosHistorico && produtosHistorico.pedidos ? produtosHistorico.pedidos.length : 0})
                            </Link>
                        </li>
                    </ul>
                </div>


                <div className="pos-sidebar-body tab-content" data-scrollbar="true" data-height="100%">
                    <div className={this.state.abaHistorico  ? "tab-pane fade h-100 show" : "tab-pane fade h-100 show active"}  id="newOrderTab">
                        {!this.context.erroCarrinho ? (
                            <>
                                {this.state.pedidoEnviado && (	
                                    <div style={{padding:"15px", textAlign:"center"}}>
                                        <Lottie animationData={lottiejson} loop={true} style={{width: "90%", margin:"auto"}} />
                                        <h2 style={{textAlign:"center"}}>Pedido confirmado <br></br>com sucesso :)</h2>
                                        <p style={{textAlign:"center", fontSize:"15px"}}>Aguarde uns instantes <br />enquanto preparamos ele.</p>

                                        <button type="button" className="btn btn-white" onClick={(event) => { event.preventDefault(); this.handleAbaPedidos()}}>
                                        VER MEUS PEDIDOS
                                        </button>
                                    </div>
                                )}
                                
                                {!this.state.pedidoEnviado && (
                                    <div style={{padding:"20px 20px 90px 20px", overflow: "scroll", position:"relative"}}>
                                        <Carrinho showHistorico={false} loading={this.props.loading} backhome={this.handleSystemMenu} atualizaStatus={this.atualizaStatus} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <div style={{padding:"15px", textAlign:"center"}}>
                                <Lottie animationData={lottiejsonErro} loop={true} style={{width: "90%", margin:"auto"}} />
                                <h2 style={{textAlign:"center"}}>Ocorreu um erro ao processar o pagamento.</h2>
                                <p style={{textAlign:"center", fontSize:"15px"}}> Verifique os dados ou tente <br/> novamente com outro cartão.</p>

                                <button type="button" className="btn btn-white" onClick={() => this.context.setErroCarrinho(false)}>
                                    Tentar novamente
                                </button>
                            </div>
                        )}
                    </div>

                    <div className={this.state.abaHistorico ? "tab-pane fade h-100 show active" : "tab-pane fade h-100 show "} id="history">
                        <div className="pos-table" style={{padding:"0"}}>
                            {produtosHistorico && (
                                <> 
                                    <address  style={{margin:"10px 20px", display:"block"}}>
                                        <strong className='h5 pt-2 mb-2'>Pedidos da minha comanda: {produtosHistorico && produtosHistorico.id}</strong>
                                    </address>

                                    {produtosHistorico.pedidos && produtosHistorico.pedidos.length > 0 ? (
                                        <div style={{background:"#DEE2E6", padding:"0 20px"}}>
                                            {produtosHistorico.pedidos.map( p => p.produtos.map( (item,i) => 
                                                <div key={"item_"+i} className="row pos-table-row">
                                                    <div className="col-9">
                                                        <div className="pos-product-thumb">
                                                            <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+item.imagem.imagem+")"}}></div>
                                                            <div className="info">
                                                                <div className="title"><strong>{item.meta.pivot_quantidade} {item.nome.toUpperCase()} </strong></div>
                                                                <div className="single-price text-gray">R$ {item.meta.pivot_valor_produto}</div>
                                                            </div>
                                                            <div className="title">
                                                                {item.variacao && item.variacao.length > 0 && (
                                                                    item.variacao[0].nome
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 total-price"> R$ {(item.meta.pivot_valor_produto * item.meta.pivot_quantidade).toFixed(2)}</div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div style={{padding:"15px", textAlign:"center"}}>
                                            <Lottie animationData={lottieHistoricoPedido} loop={true} style={{width: "90%", margin:"auto"}} />
                                            <h2 style={{textAlign:"center"}}>Nenhum pedido por aqui!</h2>
                                            <p>Acesse o cardápio, escolha seus itens favoritos e faça seu primeiro pedido.</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

           </div>
        )
    }
}