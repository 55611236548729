import React from 'react'
import { RouterProvider } from "react-router-dom"

import { routes } from '../../config/app-route'
import { AppSettings } from '../../config/app-settings'

import Login from '../organismo/login'
import Pager from '../organismo/pager'

export default class Content extends React.Component {
	static contextType = AppSettings
  
	render() {
		return (
			<AppSettings.Consumer>
				{() => (
					<div className={'app-content p-0'} style={{height:"100%"}}>
						<RouterProvider router={routes} />

						{this.context.delivery && !this.context.client && (
							<Login />
						)}

						{this.context.doLogin && (
							<Login />
						)}

						{this.context.balcao && this.context.pager && (
							<Pager />
						)}
					</div>
				)}
			</AppSettings.Consumer>
		)
	}
}