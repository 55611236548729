import React from 'react'
import { Link } from 'react-router-dom'
import { AppSettings } from '../../config/app-settings.js'
import Cartao from '../molecula/cartao.jsx'
import Offcanvas 	from 'react-bootstrap/Offcanvas'

import {addVoucheComanda, getCodigoInfo, me, getComandaDelivery, analise, confirmaPagamento, addPedido, pagamento, pagamentoBalcao, getCodigoPix, validaStatusPagameto} from '../../store/api.js';

import Lottie from "lottie-react"
import lottieSegunraca from "../../assets/seguranca.json"
import deliveryespera from "../../assets/delivery-espera.json"
import lottiemaquininha from "../../assets/pagamento-maquininha.json"
import lottiemaquininhacredito from "../../assets/cartao-credito.json"

import '@fortawesome/fontawesome-free/css/all.css';

export default class Carrinho extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this._isMounted = false

        this.state = {
            codigoDesconto: null,
            cupomDesconto:null,
            showCartao:false,
            loading:false,
            hasCard: false,
            abaCartao:false,
            abaPix:true,
            abaPosPago:false,
            chavePIX: false,
            tipoPagamento: 'PIX',
            clock: "6:00",
            timer: 300,
            timerInterval: null,
            error:null,
            taxaServico:10,
            card:{
				cvc: '',
				expiry: '',
				focus:'number',
				name: '',
				number: '',
				token: '',
				cpf:''
			},
        }

        this.setDesconto = this.setDesconto.bind(this)
        this.submitCard = this.submitCard.bind(this)
    }

    async componentDidMount() {
        this._isMounted = true
		this._isMounted && await this.reloadUser()
        this.props.loading(false)
        this.setState({taxaServico: this.context.brand && this.context.brand.loja ? this.context.brand.loja.taixa_servico : 10})
	}

    componentWillUnmount() {
        this._isMounted = false;
    }

    async submitCard(newcard){
		const card = newcard

        if(card.creditCardToken) {
			this.setState({showCartao: false, card, hasCard:true})
        }

		if (card.number && card.number.length >= 15) {
			card.creditCardNumber = card.number.substring(15)
			card.creditCardToken = null

			this.setState({showCartao: false, card: card, hasCard:true})
		}

        if (card.creditCardNumber && card.creditCardNumber.length >= 4){
            this.setState({showCartao: false, card: card, hasCard:true})
        }
	}

    async reloadUser(){
		const token = JSON.parse(localStorage.getItem('cliente'))
		const usuario = await me({items: {token: token}})

		if (token) this.setState({cliente: token})

		if (usuario.data.usuario) {
			const dataUser = usuario.data.usuario
			
			let card = this.state.card
		
			if (dataUser){
				if (dataUser.cards && dataUser.cards.length > 0 ){
					card.creditCardNumber = dataUser.cards[0].creditCardNumber
					card.creditCardToken = dataUser.cards[0].creditCardToken
					this.setState({ cards: dataUser.cards, card: card, hasCard:true })
				}

				localStorage.setItem('dataUser', JSON.stringify(dataUser))

				this.setState({ dataUser: dataUser})
			}

		} else {
			this.setState({cliente: false})
		}
	}

    async aplicarCupom(){
		const codigoInfo = await getCodigoInfo(this.state.cupomDesconto, this.context.client)

		if (codigoInfo.codigo){
			const comanda = this.context.comanda
			await addVoucheComanda({id_voucher:codigoInfo.id, id_comanda: comanda.id})
			this.setState({ codigoDesconto: codigoInfo, erroDesconto: false })
		} else {
			this.setState({ erroDesconto: true })
		}
		
	}

	calculaDesconto(){
		const total = this.context.itensCarrinho.reduce((a,item) => a += item.valor * item.quantidade, 0 )
		const desconto = (total * (this.state.codigoDesconto.valor / 100)) * -1
		return desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
	}

    setDesconto(nome, valor){
		this.setState({ cupomDesconto: valor })
	}

    totalProdutos(){
        if (this.context.itensCarrinho && this.context.itensCarrinho.length > 0) {
            return this.context.itensCarrinho.reduce((a,item) => a += item.valorTotal * item.quantidade, 0 )
        } else {
            return 0
        }
    }

    totalCarrinho(){
		let total = 0
		let desconto = 0

        if (this.context.itensCarrinho && this.context.itensCarrinho.length > 0) {
            total = this.context.itensCarrinho.reduce((a,item) => a += item.valorTotal * item.quantidade, 0 )
            
            if (this.state.codigoDesconto) desconto = (total * (this.state.codigoDesconto.valor / 100))

            total = total + (total * this.state.taxaServico) / 100
        }

		return (total - desconto) 
	}

    mudaQuantidadeCarrinho(novoValor, index){
		const items = this.context.itensCarrinho

		if(novoValor === 'add') {
			items[index].quantidade = items[index].quantidade + 1 
		} else {
			items[index].quantidade = items[index].quantidade - 1 

			if (items[index].quantidade === 0 ) {
				items.splice(index, 1)
			}
		}

		this.context.atualizaCarrinho( items )
	}

    handlerTrocaCartao(){
		this.setState({showCartao: true})
	}

    timer() {
        let time = this.state.timer

        if (time > 0) time--
        else clearInterval(this.state.timerInterval) 

        let secs = time
        const mins = Math.floor(secs/60)
        secs %= 60
        const pretty = ( (mins < 10) ? "0" : "" ) + mins + ":" + ( (secs < 10) ? "0" : "" ) + secs
        
        this.setState({clock: pretty, timer:time}) 
    }

    async pagarPedido(pix = false){
		this.setState({loading: true, tipoPagamento: pix ? 'PIX' : 'CREDITO'})
		const total = this.totalCarrinho().toFixed(2)
		let comanda = this.context.comanda
        this.context.setEmPagamento(true)

		if (comanda){
			const c = await getComandaDelivery({id:comanda.id}) 
			this.context.handleSetComanda(c.data)
		} else {
			const c = await getComandaDelivery()
			comanda = c.data
			this.context.handleSetComanda(c.data)
		}
		
		const data = { 
			valor: total, 
			itens: this.context.itensCarrinho, 
			id_comanda:comanda.id 
		}

		const dataPagamento = {
			items:{
				pagamento:{
					valor:total,
					tipo:pix ? "PIX" : "CREDITO",
				   	b_taxa: this.state.taxaServico > 0 ? true : false,
				   	valor_taxa: this.state.taxaServico,
				},
				card:this.state.card,
				id_comanda:comanda.id
			},
        
            token: pix ? "25ECFEB7454C05E03490F2AB568157D5" : this.context.client
		}
		
		//coloca comanda em analise
		if(!this.state.error && !this.context.pedidoEmProcessamento) {
            await analise({id:comanda.id})
            await addPedido(data)
            this.context.setPedidoEmPocessamento(true)
        }

        try {
            const pagamentoRetorno = pix ? await pagamento(dataPagamento) : await pagamentoBalcao(dataPagamento)

            if (pagamentoRetorno.data.success) {
                if (pix) {
                    const resultPIX = await getCodigoPix({items:{id_comanda:comanda.id}})

                    if (resultPIX.data){
                        this.setState({chavePIX: resultPIX.data.payload})

                        const interval = setInterval(() => {
                            validaStatusPagameto({items:{id_comanda:comanda.id}}).then(status => {
                                if (status.data.status === "RECEIVED" || status.data.status === "CONFIRMED") {
                                    clearInterval(interval)
                                    
                                    this.setState({chavePIX: false})
                                    confirmaPagamento({id:comanda.id}).then(() => {
                                        const intervalCont = this.state.timerInterval
                                        clearInterval(intervalCont)
                                    })
                                    
                                    this.context.handleSetComanda(null)
                                    this.context.atualizaCarrinho([])
                                    this.context.sethistoricoCarrinho(comanda)  
                                    this.props.atualizaStatus()
                                    this.context.setEmPagamento(false)
                                    this.context.setPedidoEmPocessamento(false)
                                    
                                }
                            })
                        }, 3000)

                        const contdown = setInterval(() => {
                            this.timer()
                            this.setState({ timerInterval:contdown })
                        }, 3000)
                    }
                } else {
                    this.context.atualizaCarrinho([])
                    this.context.sethistoricoCarrinho(comanda)
                    this.props.atualizaStatus()
                    this.context.setEmPagamento(false)
                    this.context.setPedidoEmPocessamento(false)
                }

                this.setState({loading: false})
            } else {
                this.setState({ loading: false, error: true })
            }
        } catch (e){
            this.setState({loading: false, error: true})
            this.context.setErroCarrinho(true)
        }
	}

    async enviaPedido(){
        const total = this.this.totalCarrinho().toFixed(2)
        const comanda = this.context.comanda
        const data = { 
            valor: total, 
            itens: this.context.itensCarrinho, 
            id_comanda:comanda.id 
        }
        
        data.mesa = this.context.mesa

        await addPedido(data)

        this.context.sethistoricoCarrinho(comanda)
        this.context.atualizaCarrinho([])
        this.setState({ pedidoEnviado: true})
        this.props.atualizaStatus()
    }

    copiaChavePIX(e) {
        e.preventDefault()

        const copyText = this.state.chavePIX

        navigator.clipboard.writeText(copyText)
    }

    render() {
        return (
            <div>
                {!this.state.chavePIX && (
                    <div className="pos-table" style={{backgroundColor:"#DEE2E6"}}>
                        {this.context.itensCarrinho && this.context.itensCarrinho.length > 0 && this.context.itensCarrinho.map((item,i) =>
                            <div key={"item_"+i} className="row pos-table-row">
                                <div className="col-9">
                                    <div className="pos-product-thumb">
                                        {item.imagem ? (
                                            <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+item.imagem.imagem+")"}}></div>
                                        ) : (
                                            <div className="img" style={{backgroundImage: "url(https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg)"}}></div>	
                                        )}
                                        <div className="info">
                                            <div className="title">{item.nome.toUpperCase()}</div>
                                            <div className="title" style={{fontSize:'12px'}}>
                                                {item.variacao && item.variacao.length > 0 && (
                                                    item.variacao[0].nome
                                                )}
                                            </div>

                                            <div className="single-price"> R$ {item.valor} </div>
                                            
                                            {item.opcionais && item.opcionais.length > 0 && (
                                                item.opcionais.map(i => (
                                                    <div className="title" style={{fontSize:'12px'}}> Retirar {i.nome} </div>
                                                ))
                                            )}

                                            {item.adicionais && item.adicionais.length > 0 && (
                                                <>
                                                    {item.adicionais.map(i =>( 
                                                        <>
                                                            {parseInt(i.quantity) > 0 ? ( 
                                                                <div className="title" style={{fontSize:'12px'}}>  
                                                                    {`${i.nome} ${i.quantity} - ${i.valor ? "R$ " + (parseFloat(i.valor) * parseInt(i.quantity)) : ""}`} 
                                                                </div>
                                                            ):(<></>)}
                                                        </>
                                                    ))}
                                                </>
                                            )}

                                            {!this.context.pedidoEmProcessamento && (
                                                <div className="input-group qty">
                                                    <div className="input-group-append">
                                                        <Link to="#" className="btn btn-default" onClick={() => this.mudaQuantidadeCarrinho('remove', i)}><i className="fa fa-minus"></i></Link>
                                                    </div>
                                                    <input type="text" className="form-control" value={item.quantidade} readOnly/>
                                                    <div className="input-group-prepend">
                                                        <Link to="#" className="btn btn-default" onClick={() => this.mudaQuantidadeCarrinho('add', i)}><i className="fa fa-plus"></i></Link>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-3 total-price">{(parseFloat(item.valorTotal) * parseInt(item.quantidade)).toFixed(2)}</div>
                            </div>
                        )}
                    </div>
                )}

                {this.context.comanda && this.state.chavePIX && (
                    <div className='fade show col-md-12' style={{textAlign:'center'}}>
                        <div style={{width:"50%", margin:"auto"}}>
                            <Lottie animationData={deliveryespera} loop={true} />
                        </div>
                        <h3>Estamos quase lá :)</h3>
                        <p>
                            Geramos um codigo PIX para poder <b>finalizar o pagamento</b> e enviar o pedido para cozinha
                        </p>
                    </div>
                )}

                <hr />

                <div className='mt-3'>

                    <div className='row mt-3'>
                        <ul className="nav nav-tabs">
                           
                            <li className="nav-item" style={this.state.abaPix ? {backgroundColor:"#eeeeee", borderRadius:"5px 5px 0 0", cursor:"pointer"} : {cursor:"pointer"}}>
                                <div onClick={() => this.setState({abaCartao: false, abaPix:true, abaPosPago:false})} className="nav-link" style={{color:"#000"}}>  <i className="fa-brands fa-pix" style={{color:"#02bbac"}}></i> PIX</div>
                            </li>
                            {!this.state.chavePIX && (
                                <>
                                    <li className="nav-item" style={this.state.abaCartao ? {backgroundColor:"#eeeeee", borderRadius:"5px 5px 0 0", cursor:"pointer",  marginLeft:"5px"} : {cursor:"pointer", marginLeft:"5px"}}>
                                        <div onClick={() => this.setState({abaCartao: true, abaPix:false, abaPosPago:false})}  className="nav-link" style={{color:"#000"}}> <i className="fa-solid fa-credit-card" style={{color:"#0078ff"}}></i> CRÉDITO</div>
                                    </li>
                                
                                    {/* <li className="nav-item" style={this.state.abaPosPago ? {backgroundColor:"#eeeeee", borderRadius:"5px 5px 0 0", cursor:"pointer", marginLeft:"5px"} : {cursor:"pointer", marginLeft:"5px"}}>
                                        <div onClick={() => this.setState({abaCartao: false, abaPix:false, abaPosPago:true})} className="nav-link" style={{color:"#000"}}>  <i className="fa-solid fa-hand" style={{color:"blue"}}></i> NA MESA </div>
                                    </li> */}
                                </>
                            )}
                        </ul>
                        
                        <div style={{backgroundColor:"#eeeeee", padding: "20px"}}>
                            <div style={this.state.abaCartao ? {display:"block"} : {display:"none"}}>
                                <div>
                                    <Lottie animationData={lottiemaquininhacredito} loop={true} style={{width: "40%", margin:"auto"}} />
                                    <h5>Pagamento rápido e sem cadastro.</h5>
                                    <p> Basta informar os dados do cartão e concluir a compra.</p>
                                </div>
                                <button className="col-12 mt-3 btn btn-outline-primary btn-lg me-1 mb-1" onClick={() => {this.setState({showCartao: true})}}>
                                    <i className="fa-solid fa-credit-card"></i> Informar dados
                                </button>
                            </div>

                            <div style={this.state.abaPix ? {display:"block"} : {display:"none"}}>
                                {this.state.chavePIX ? (
                                    <div>
                                        <button className="col-12 mt-3 btn btn-pix btn-lg me-1 mb-1" onClick={(e) => this.copiaChavePIX(e)}>
                                        <i className="fa-brands fa-pix"></i> Copiar código PIX
                                        </button>

                                        <p style={{margin: "10px 0 0 0"}}>Efetue o pagamento em até:</p>
                                        <i className="fa-brands fa-pix" style={{fontSize: "20px", color:"#02bbac"}}></i>
                                        <strong style={{fontSize: "20px", pading:"20px 10px"}}> {this.state.clock} </strong>
                                    </div>
                                ):(
                                    <>
                                        {parseInt(this.totalCarrinho()) < 5 ? (
                                            <p>Escolha pelo menos 1 produto acima de R$ 5,00 para finalizar seu pedido.</p>
                                        ):(
                                            <>
                                                <Lottie animationData={lottieSegunraca} loop={true} style={{width: "40%", margin:"auto"}} />
                                                <h5>Gerar código PIX.</h5>
                                                <p>Copie o código gerado no seu banco. Após a conclusão do pagamento, enviaremos o pedido ao estabelecimento.</p>
                                                
                                                <div>                                        
                                                    <button className="col-12 mt-3 btn btn-outline-pix btn-lg me-1 mb-1" onClick={() => {this.pagarPedido(true)}}>
                                                        <i className="fa-brands fa-pix"></i> Gerar código PIX Copia e Cola
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>

                            <div style={this.state.abaPosPago ? {display:"block"} : {display:"none"}}>
                                <Lottie animationData={lottiemaquininha} loop={true} style={{width: "40%", margin:"auto"}} />
                                <h2>Peça agora, pague depois</h2>
                                <p>Faça o pedido e pague com o garçom na entrega usando a maquininha.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col-6 h6">Subtotal</div> 
                    <div className="col-6" style={{textAlign:"right"}}> <strong>{ this.totalProdutos().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                </div>

                <div className="row">
                    <div className="col-6 h6">Taxa de serviço</div> 
                    <div className="col-6" style={{textAlign:"right"}}> <strong>{ ((this.totalProdutos() * this.state.taxaServico) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                </div>
                    
                {this.state.codigoDesconto && (
                    <div className="row">
                        <div className="col-8">Descontos e promoções</div> 
                        <div className="col-4" style={{textAlign:"right", fontSize:"14px", color:"#0078FF"}}> <strong>{  this.calculaDesconto()  } </strong></div>
                    </div>
                )}

                <div className="row">
                    <div className="col-6">Total</div> 
                    <div className="col-6" style={{textAlign:"right", fontSize:"14px", color:"green"}}> <strong>{ this.totalCarrinho().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                </div>

                {!this.state.hasCard && this.state.abaCartao && (
                    <div className='alert alert-primary fade show col-md-12 mt-3' style={{textAlign:"center"}}>
                        Para finalizar a compra precisa incluir um método de pagamento.
                    </div>
                )}

                <div className="btn-row mt-3">
                    {/* {!this.state.abaHistorico && this.context.itensCarrinho.length > 0 && this.state.abaPosPago && (
                        <Link to="#" className="btn btn-success mb-3" style={{width:"100%"}} onClick={(event) => { event.preventDefault(); this.enviaPedido(); }}>
                            <i className="fa fa-check fa-fw fa-lg"></i> Enviar Pedido
                        </Link>
                    )} */}

                    {!this.state.abaHistorico && this.context.itensCarrinho.length > 0 && this.state.abaCartao && this.state.hasCard && (
                        <button className="col-12 mt-3 btn btn-primary  btn-lg me-1 mb-1" onClick={() => this.pagarPedido()}>
                            Efetuar pagamento
                        </button>
                    )}
                </div>

                <Offcanvas show={this.state.showCartao} onHide={() => this.setState({showCartao: false})}  placement="bottom" style={{height:"90%", padding:"20px"}}>
                    <Cartao submitCard={this.submitCard} noLogin={true}/>
                </Offcanvas>

                {this.state.loading && (
				    <div className="pos-sidebar" style={{top: "unset", display:"block", height: "100%", background:"#fff", position: "fixed", zIndex:"1030", padding:"10px",right: "0", top: "0"}}>
						<Lottie animationData={lottieSegunraca} loop={true} style={{width: "100%", margin:"auto"}} />

                        {this.state.tipoPagamento === 'PIX' ? (
                            <h4 style={{textAlign:"center"}}>Aguarde uns instantes, estamos gerando o código PIX...</h4>
                        ):(
                            <h4 style={{textAlign:"center"}}>Aguarde uns instantes, estamos finalizando seu pagamento...</h4>
                        )}
						
					</div>
				)}
            </div>
        )
    }
}