
import React from 'react'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { getDestaques } from '../../store/api.js';
import { AppSettings } from '../../config/app-settings.js'

export default class ProductList extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
		super(props)

		this.state = {
            tema: 'themaLine',
            brandData:null,
            open:false,
            carrinho:false,
			modalPosItem: false,
            
			produto: null,
            opcionais: [],
            adicionais: [],
            destaques: [],
            screenSize: "mobile",
            opcionaisSelecionados: [],
            adicionaisSelecionados: [],
            variacao:null,
            complemento:null,
		}

        this.addCarrinho = this.addCarrinho.bind(this)
        this.isProductValid = this.isProductValid.bind(this)
	}

    async componentDidMount() {
        const destaques = await getDestaques()
        
        this.setState({ tema: this.context.appTheme })
        this.setState({destaques: destaques.data.sort((a,b) => a.prioridade - b.prioridade)})
		this.setState({screenSize: this.context.screenSize, open: this.context.open})
        this.setState({brandData:this.context.brand, carrinho:this.context.carrinho})
	}

    verifyDays(value) {
		let result = false
		const d = new Date()

		const weekday = ["0x0000001","0x0000010","0x0000100","0x0001000","0x0010000","0x0100000","0x1000000"]
		const binario = '0x'+(value >>> 0).toString(2).padStart(7, 0)

		if (!value) result = true
		else result = !!(weekday[d.getDay()] & binario)
		
		return result
	}

	price(item){
        const valor = this.context.delivery && item.valor_delivery ? item.valor_delivery : item.valor
        const valorPromo = this.context.delivery && item.valor_promocional_delivery ? item.valor_promocional_delivery : item.valor_promocional
        
        let variacoes = this.context.delivery ? item.variacao.filter( (e) => e.b_delivery === 1 ) : item.variacao.filter( (e) => e.b_delivery === 0 )
        let result = "R$ "+valor
        
        variacoes = variacoes.sort((a,b) => {
            return parseFloat(a.valor) - parseFloat(b.valor)
        })
       
        if(item.b_personalizado && variacoes && variacoes.length > 0) {
            result = "A partir de R$ "+variacoes[0].valor
        } else if(item.b_promocao && valorPromo && this.verifyDays(item.dias_promocao)) {
            result = "De R$ "+valor+" por R$ "+valorPromo
        } else if(item.b_promocao_delivery && valorPromo && this.verifyDays(item.dias_promocao)) {
            result = "De R$ "+valor+" por R$ "+valorPromo
        }

		return result
	}

    async detalheProduto(event, item) {
		event.preventDefault()

		if (!this.state.modalPosItem) {
			const opcionais = item.opcionais
			const adicionais = item.adicionais.map(item => ({
                ...item,
                quantity: 0  
            }))

			this.setState({ produto: item, modalPosItem: true, opcionais: opcionais, adicionais: adicionais })
		} else {
			this.setState({ produto: null, modalPosItem: false, opcionais: [], adicionais: [] })
		}
	}

    getImagem(item){
        return item.imagem ? "url(https://grubupload.s3.amazonaws.com/"+item.imagem.imagem+")" : "url(https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg)"
    }

    async handlerScroll(event) {
		this.setState({ scrollModalPosition: event.target.scrollTop })
	}

    handleOpcionais(item){
        const isMinMaxOpcional = this.state.produto.b_quantidade_opcional
        const maxOpicional = this.state.produto.max_opcional

		let op =  this.state.opcionaisSelecionados
		
		if ( op.find(i => {return i.nome === item.nome}) ){
			op = op.filter(element => element !== item)
            this.setState( {opcionaisSelecionados: op} )
		} else {
            if( !isMinMaxOpcional || (isMinMaxOpcional && this.state.opcionaisSelecionados.length < maxOpicional) ) {
                op.push(item)
                this.setState( {opcionaisSelecionados: op} )
            }
		}
	}

	handleComentario(valor){
		this.setState( {complemento: valor} )
	}

    async handleAddCarrinho(event, item){
        event.preventDefault()

        if (item.opcionais.length > 0 || item.adicionais.length > 0 || (item.varicao && item.varicao.length > 0)) {
            this.detalheProduto(event, item)
        } else {
            await this.setState({ produto: item})
            await this.setState({ opcionais: item.opcionais, adicionais: item.adicionais })
            this.addCarrinho()
        }
      
    }

    isProductValid(){
        const produto = this.state.produto
        let varicao = false
        //let adicionais = false
        let opcionais = false

        if (produto.b_personalizado && this.state.variacao && produto.variacao.length > 0) {
            varicao = true
        } else if (!produto.b_personalizado) {
            varicao = true
        }

        if (produto.b_quantidade_opcional && this.state.opcionaisSelecionados.length >= produto.min_opcional) {
            opcionais = true
        } else if (!produto.b_quantidade_opcional) {
            opcionais = true
        }
        
        // if (produto.b_quantidade_adicional && this.state.adicionaisSelecionados.length >= produto.min_adicional){
        //     adicionais = true
        // } else if (!produto.b_quantidade_adicional) {
        //     adicionais = true
        // }

        if (varicao && opcionais){ // && adicionais) {
            return true
        } else {
            return false
        }
    }

    addCarrinho(){
        const produto = this.state.produto
        if (produto) {
            let price_tag = produto.b_promocao && produto.valor_promocional && this.verifyDays(produto.dias_promocao) ? parseFloat(produto.valor_promocional) : parseFloat(produto.valor)

            if ( produto.b_personalizado && produto.variacao.length > 0 ) {
                price_tag = parseFloat(this.state.variacao.valor)
            }

            if (!this.state.variacao && this.context.delivery && (produto.valor_promocional_delivery || produto.valor_delivery)){
                price_tag = produto.b_promocao_delivery && produto.valor_promocional_delivery && this.verifyDays(produto.dias_promocao) ? parseFloat(produto.valor_promocional_delivery) : parseFloat(produto.valor_delivery)
            }

            const valorAdicionais = this.state.adicionaisSelecionados.reduce( (total, i) => {return parseFloat(total) + (parseFloat(i.valor) * parseInt(i.quantity)) }, 0 )
            
            const obj = {
                nome: produto.nome,
                id_produto: produto.id,
                imagem: produto.imagem,
                quantidade:1,
                variacao:this.state.variacao ? [this.state.variacao] : [],
                valor: price_tag,
                opcionais: this.state.opcionaisSelecionados,
                adicionais: this.state.adicionaisSelecionados,
                totalAdicional: valorAdicionais,
                complemento: this.state.complemento,
                valorTotal: (parseFloat(price_tag) + parseFloat(valorAdicionais)).toFixed(2)
            }

            this.context.addCarrinho(obj)
            this.setState({ modalPosItem:false, variacao: null, opcionaisSelecionados:[], adicionaisSelecionados:[], complemento: null })
        }
    }

    handleDecrease(event){
        event.preventDefault()
        const {objid} = event.currentTarget.dataset
        const adicionais = this.state.adicionais

        this.setState({ 
            adicionaisSelecionados: this.state.adicionaisSelecionados.flatMap((item) => {
                if (item.id === parseInt(objid)) {
                    if (item.quantity > 1) {
                        return [{
                            ...item,
                            quantity: item.quantity - 1
                        }];
                    } else {
                        return []; // Remove item by returning empty array
                    }
                }
                return [item];
            })
        });

        this.setState({ adicionais: adicionais.map((item) => {
                if (item.id === parseInt(objid)) {
                    return {
                        ...item,
                        quantity: item.quantity > 0 ? item.quantity - 1 : 0
                    }
                }
                return item
            })
        })  
    }
    
    handleIncrease(event) {
        event.preventDefault();
        const {objid, max} = event.currentTarget.dataset;
        const adicionais = this.state.adicionais;
    
        // Update adicionais state normally
        this.setState({
            adicionais: adicionais.map((item) => {
                if (item.id === parseInt(objid)) {
                    return {
                        ...item,
                        quantity: item.quantity < max ? item.quantity + 1 : item.quantity
                    }
                }
                return item;
            })
        });
    
        // Find the item being updated
        const targetItem = adicionais.find(item => item.id === parseInt(objid));
        
        if (targetItem) {
            const newQuantity = targetItem.quantity < max ? targetItem.quantity + 1 : targetItem.quantity;
            
            // If item doesn't exist in adicionaisSelecionados and new quantity > 0, add it
            // If item exists, update its quantity
            this.setState(prevState => {
                const itemExists = prevState.adicionaisSelecionados.some(
                    item => item.id === parseInt(objid)
                );
    
                if (!itemExists && newQuantity > 0) {
                    // Add new item
                    return {
                        adicionaisSelecionados: [
                            ...prevState.adicionaisSelecionados,
                            { ...targetItem, quantity: newQuantity }
                        ]
                    };
                } else {
                    // Update existing item
                    return {
                        adicionaisSelecionados: prevState.adicionaisSelecionados.map((item) => {
                            if (item.id === parseInt(objid)) {
                                return {
                                    ...item,
                                    quantity: newQuantity
                                };
                            }
                            return item;
                        })
                    };
                }
            });
        }
    }
    
    
    render() {
        return (
            <div className="product-row">
                {this.state.destaques.length > 0 && this.context.delivery && !this.props.filter && this.state.tema !== "distribuidora" && this.state.destaques.map((d, i) =>
                    <div className='row'>
                        <h3 className="mt-3 mb-3">{d.nome}</h3>
                        <div className={i === 0 ? 'row cardList' : 'row'}>
                            {d.produtos.map((item) =>
                                <div key={"product"+item.id} className="product-container" data-type={item.id_categoria}>
                                    <Link to="#" className="product" onClick={(event) => this.detalheProduto(event, item)}>

                                        <div className="img position-relative" style={{backgroundImage: this.getImagem(item)}}>
                                            {item.b_promocao && item.valor_promocional && this.verifyDays(item.dias_promocao) ? (
                                                <span className="badge bg-danger position-absolute promocao">PROMOÇÃO</span>	
                                            ) : (<></>)}
                                            <span className="badge categories bg-dark rounded-pill position-absolute" style={{bottom:"10px", left:"10px", fontSize:"0.85em"}}>{item.categoria ? item.categoria.nome.toUpperCase() : ""}</span>
                                        </div>

                                        <div className="text">
                                            {this.state.tema === "distribuidora" ? (
                                                <>
                                                    <div className="price">
                                                        {this.price(item)}
                                                    </div>
                                                    <div className="title">{item.nome.toCamelCase()}</div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="title">{item.nome.toUpperCase()}</div>
                                                    <div className="desc" style={{position:'relative'}} >
                                                        {item.descricao}	
                                                    </div>

                                                    {i !== 0 && (
                                                        <div className="gradiente" style={{background: 'linear-gradient(180deg, rgb(255 255 255 / 0.1) 0%, rgb(255 255 255) 100%)', height:'100%', width:'100%', position:'absolute', bottom:'0', zIndex:'10'}}></div>
                                                    )}

                                                    <div className="price">
                                                        {this.price(item)}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {this.state.destaques.length > 0 && !this.props.filter && this.state.tema !== "distribuidora" && this.context.delivery && (
                    <div className='col-12'>
                        <h3  className="mt-3 mb-3">Todos os produtos</h3>
                    </div>
                )}

                {this.props.lista.map((item) =>
                    <div key={"product"+item.id} className="product-container normal" data-type={item.id_categoria}>
                        {this.state.tema === "distribuidora" ? (
                            <div className="product">
                                <Link to="#" className="product" onClick={(event) => this.detalheProduto(event, item)}>
                                    <div className="img position-relative" style={{backgroundImage: this.getImagem(item)}}>
                                        {item.b_promocao && item.valor_promocional && this.verifyDays(item.dias_promocao) ? (
                                            <span className="badge bg-danger position-absolute promocao">PROMOÇÃO</span>	
                                        ) : (<></>)}
                                        <span className="badge categories bg-dark rounded-pill position-absolute" style={{bottom:"10px", left:"10px", fontSize:"0.85em"}}>{item.categoria ? item.categoria.nome.toUpperCase() : ""}</span>
                                    </div>
                                </Link>

                                <div className="text" style={{minHeight: "150px"}}>
                                    <div className="price green"> {this.price(item)} </div>
                                    <div className="title" style={{fontSize:"12px"}}>{item.nome.toUpperCase()}</div>
                                    {this.state.brandData && this.state.brandData.loja.b_pedidos && this.state.carrinho && this.state.open ? (
                                        <Link to="/pos/customer-order" className="btn btn-success" style={{position: 'absolute', bottom: '10px', width: '83%'}} onClick={(event) => {this.handleAddCarrinho(event, item)}}>Adicionar</Link>
                                    ) : (
                                        <Link to="#"className="btn btn-success" style={{position: 'absolute', bottom: '10px', width: '83%'}}  onClick={(event) => this.detalheProduto(event, item)}>Ver Produto</Link>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <Link to="#" className="product" onClick={(event) => this.detalheProduto(event, item)}>

                                <div className="img position-relative" style={{backgroundImage: this.getImagem(item)}}>
                                    {item.b_promocao && item.valor_promocional && this.verifyDays(item.dias_promocao) ? (
                                        <span className="badge bg-danger position-absolute promocao">PROMOÇÃO</span>	
                                    ) : (<></>)}
                                    <span className="badge categories bg-dark rounded-pill position-absolute" style={{bottom:"10px", left:"10px", fontSize:"0.85em"}}>{item.categoria ? item.categoria.nome.toUpperCase() : ""}</span>
                                </div>

                                <div className="text" style={{minHeight: "150px"}}>
                                    <div className="title">{item.nome.toUpperCase()}</div>
                                    <div className="desc" style={{position:'relative'}} >
                                        {item.descricao}	
                                    </div>
                                    <div className="gradiente" style={{background: 'linear-gradient(180deg, rgb(255 255 255 / 0.1) 0%, rgb(255 255 255) 100%)', height:'100%', width:'100%', position:'absolute', bottom:'0', zIndex:'10'}}></div>
                                    <div className="price">
                                        {this.price(item)}
                                    </div>
                                </div>

                            </Link>
                        )}
                        
                    </div>
                )}

                <Modal isOpen={this.state.modalPosItem} size="lg" toggle={(event) => this.detalheProduto(event, this.state.produto.id)} modalClassName="modal-pos-item">
                    <ModalBody className="p-0" style={{position:"relative", height:"100%", overflow:"hidden"}}>
                            {this.state.screenSize === "mobile" && this.state.produto && (
                                <div className="pos-product" onScroll={(e) => {this.handlerScroll(e)}} style={{overflow:"scroll"}}>
                                    <div className="pos-product-img" style={this.state.scrollModalPosition > 50 ? { transition: "height 1s", height:"20%" } : { transition: "height 1s", height:"50%"} } onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>
                                    {this.state.produto.imagem ? (
                                        <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+this.state.produto.imagem.imagem+")"}}></div>
                                    ) : (
                                        <div className="img" style={{backgroundImage: "url(https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg)"}}></div>	
                                    )}
                                    </div>
                                    <div className="pos-product-info" style={{overflow: "hidden", minHeight: "88%", height: "auto"}}>
                                        <div className="title">{this.state.produto.nome.toUpperCase()}</div>
                                        <div className="desc">{this.state.produto.descricao}</div>
                                        <div className="price">{this.price(this.state.produto)}</div>
                                        
                                        {this.state.produto.b_personalizado && this.state.produto.variacao.length > 0 ? (
                                            <div className="option-row" style={{marginBottom:"50px"}}>
                                                <div className="option-list">
                                                    {this.state.produto.variacao.map( (item) => (
                                                        <>
                                                            {item.b_delivery == 1 ? (
                                                                    <div key={"variacao_"+item.nome} className="option" onClick={() => { this.setState({variacao: item}) }}>
                                                                        <label className="option-label" htmlFor="size3" style={this.state.variacao === item ? {borderColor:'#0078ff'} : {}}>
                                                                            <span className="option-text">{item.nome}</span>
                                                                            <span className="option-price">R$ {item.valor}</span>
                                                                        </label>
                                                                    </div>
                                                            ) : (
                                                                    <div key={"variacao_"+item.nome} className="option" onClick={() => { this.setState({variacao: item}) }}>
                                                                        <label className="option-label" htmlFor="size3" style={this.state.variacao === item ? {borderColor:'#0078ff'} : {}}>
                                                                            <span className="option-text">{item.nome}</span>
                                                                            <span className="option-price">R$ {item.valor}</span>
                                                                        </label>
                                                                    </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (<></>)}

                                        {this.state.opcionais.length > 0 && (
                                            <div className="option-row">
                                                <h4>Deseja algum opcional?</h4>
                                                {this.state.produto.b_quantidade_opcional && (
                                                    <p>Escolha obirgatória minimo: {this.state.produto.min_opcional} e maximo: {this.state.produto.max_opcional}</p>
                                                )}

                                                <div className="option-list">
                                                    {this.state.opcionais.map( (item) => {
                                                        return (
                                                            <div key={"variacao_"+item.nome} className="option" onClick={() => { this.handleOpcionais(item) }}>
                                                                <label className="option-label" htmlFor="size3" style={this.state.opcionaisSelecionados.find((i) => {return i.nome === item.nome}) ? {borderColor:'#0078ff'} : {}}>
                                                                    <span className="option-text"> {item.nome} </span>
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}

                                        {this.state.adicionais.length > 0 && (	

                                            <div className="option-row">
                                                <h4>Deseja incluir algum adicional?</h4>
                                                {this.state.produto.b_quantidade_adicional && (
                                                    <p>Escolha obirgatória minimo: {this.state.produto.min_adicional} e maximo: {this.state.produto.max_adicional}</p>
                                                )}

                                                <div className="option-list" style={{display:"block"}}>
                                                    {this.state.adicionais.map( (item, index) => {
                                                        return (
                                                            <div key={"variacao_"+item.nome} className="option" style={{width: "100%", maxWidth: "none"}}>
                                                                <label className="option-label" htmlFor="size3"  style={{alignItems: "unset", flexDirection: "row", justifyContent: "space-between"}}>
                                                                    <div style={{width:"50%"}}>
                                                                        {item.imagem && (
                                                                            <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+item.imagem+")", height: '32px', width: '32px', borderRadius: '10px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', float: "left", marginRight: "5px", backgroundPosition: "center"  }}></div>
                                                                        )}
                                                                        <span className="option-text" style={{textAlign: "left"}}> {item.nome} </span>
                                                                        
                                                                        {item.valor && (
                                                                            <span className="option-price">R$ {item.valor}</span>
                                                                        )}
                                                                    </div>

                                                                    <div className="input-group qty" style={{width: "50%", float: "right", maxWidth: "none"}}>
                                                                        <div data-objid={item.id} onClick={(e) => this.handleDecrease(e)} className="input-group-append">
                                                                            <span className="btn btn-default"> <i className="fa fa-minus"></i> </span>
                                                                        </div>
                                                                        <input type="text" className="form-control" value={item.quantity} readOnly style={{border: "none", textAlign: "center"}} />
                                                                        <div data-objid={item.id} data-max={item.max} onClick={(e) => this.handleIncrease(e)} className="input-group-prepend">
                                                                            <span className="btn btn-default">  <i className="fa fa-plus"></i> </span>
                                                                        </div> 
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        
                                        )}

                                        {this.state.carrinho && (
                                            <div className="form-floating"  style={{minHeight: "100px", overflow: "hidden"}}>
                                                <div>Alguma observação?</div>
                                                <textarea className='form-control' style={{height: "50px"}} name="complemento" onChange={(e) => this.handleComentario(e.target.value)} />
                                            </div>
                                        )}
                                    </div>

                                    <div className="btn-row" style={{position:"absolute", bottom:"0", width:"100%", zIndex:"1000"}}>
                                        {this.state.brandData && this.state.brandData.loja.b_pedidos && this.state.carrinho && this.state.open ? (
                                            <>
                                                <Link to="/pos/customer-order" className="btn btn-default" style={{width:'40%', height: '100%', fontSize: "15px", paddingTop:'15px'}} onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>Fechar</Link>
                                                {this.isProductValid() ?
                                                    <Link to="/pos/customer-order" className="btn btn-success" style={{width:'60%', height: '100%', fontSize: "15px", paddingTop:'15px'}} onClick={(event) => { event.preventDefault(); this.addCarrinho()}}>Adicionar produto&nbsp; <i className="fa fa-plus fa-fw ml-2"></i></Link>
                                                :
                                                    <div className="btn btn-success" style={{filter:'grayscale(1)', width:'60%', height: '100%', fontSize: "15px", paddingTop:'15px'}} >Adicionar produto&nbsp; <i className="fa fa-plus fa-fw ml-2"></i></div>
                                                }
                                            </>
                                        ) : (
                                            <>
                                                {!this.state.open && (
                                                    <div className="alert alert-danger fade show"  style={{ position: "absolute", bottom: "27px", width:"100%", textAlign:"center" }}>
                                                        Estamos fechados em este momento.
                                                    </div>
                                                )}
                                                <Link to="/pos/customer-order" style={{width:'100%', height:'100%', fontSize: "17px"}} className="btn btn-default" onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>FECHAR PRODUTO</Link>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}

                            {this.state.screenSize === "tablet" && this.state.produto &&  (
                                <div className="pos-product">
                                    <div className="pos-product-img"  onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>
                                        {this.state.produto.imagem ? (
                                            <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+this.state.produto.imagem.imagem+")"}}></div>
                                        ) : (
                                            <div className="img" style={{backgroundImage: "url(https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg)"}}></div>	
                                        )}
                                    </div>
                                    <div className="pos-product-info">
                                        <div  style={{ overflowX: "hidden", overflowY: "auto", height:"90%"}}>
                                            <div className="title">{this.state.produto.nome.toUpperCase()}</div>
                                            <div className="desc">{this.state.produto.descricao}</div>
                                            <div className="price">{this.price(this.state.produto)}</div>
                                            
                                            {this.state.produto.b_personalizado && this.state.produto.variacao.length > 0 ? (
                                                <div className="option-row">
                                                    <div className="option-list">
                                                        {this.state.produto.variacao.map( (item) => 
                                                            <>
                                                                {item.b_delivery == 1 ? (
                                                                    <div key={"variacao_"+item.nome} className="option" onClick={() => { this.setState({variacao: item}) }}>
                                                                        <label className="option-label" htmlFor="size3" style={this.state.variacao === item ? {borderColor:'#0078ff'} : {}}>
                                                                            <span className="option-text">{item.nome}</span>
                                                                            <span className="option-price">R$ {item.valor}</span>
                                                                        </label>
                                                                    </div>
                                                                ) : (
                                                                    <div key={"variacao_"+item.nome} className="option" onClick={() => { this.setState({variacao: item}) }}>
                                                                        <label className="option-label" htmlFor="size3" style={this.state.variacao === item ? {borderColor:'#0078ff'} : {}}>
                                                                            <span className="option-text">{item.nome}</span>
                                                                            <span className="option-price">R$ {item.valor}</span>
                                                                        </label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (<></>)}

                                            {this.state.opcionais.length > 0 && (
                                                <div className="option-row">
                                                    <h4>Deseja algum opcional?</h4>
                                                    {this.state.produto.b_quantidade_opcional && (
                                                        <p>Escolha obirgatória minimo: {this.state.produto.min_opcional} e maximo: {this.state.produto.max_opcional}</p>
                                                    )}

                                                    <div className="option-list">
                                                        {this.state.opcionais.map( (item) => {
                                                            return (
                                                                <div key={"variacao_"+item.nome} className="option" onClick={() => { this.handleOpcionais(item) }}>
                                                                    <label className="option-label" htmlFor="size3" style={this.state.opcionaisSelecionados.find((i) => {return i.nome === item.nome}) ? {borderColor:'#0078ff'} : {}}>
                                                                        <span className="option-text"> {item.nome} </span>
                                                                    </label>
                                                                </div>
                                                            )
                                                        } )}
                                                        
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.adicionais.length > 0 && (	

                                                <div className="option-row">
                                                    <h4>Deseja incluir algum adicional?</h4>
                                                    {this.state.produto.b_quantidade_adcional && (
                                                        <p>Escolha obirgatória minimo: {this.state.produto.min_odicional} e maximo: {this.state.produto.max_adicional}</p>
                                                    )}

                                                    <div className="option-list" style={{display: "block"}}>
                                                        {this.state.adicionais.map( (item, index) => {
                                                            return (
                                                                <div key={"variacao_"+item.nome} className="option" style={{width: "100%", maxWidth: "none"}}>
                                                                    <label className="option-label" htmlFor="size3"  style={{alignItems: "unset", flexDirection: "row", justifyContent: "space-between"}}>
                                                                        <div style={{width:"50%"}}>
                                                                            {item.imagem && (
                                                                                <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+item.imagem+")", height: '32px', width: '32px', borderRadius: '10px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', float: "left", marginRight: "5px", backgroundPosition: "center" }}></div>
                                                                            )}
                                                                            <span className="option-text" style={{textAlign: "left"}}> {item.nome} </span>
                                                                            {item.valor && (
                                                                                <span className="option-price">R$ {item.valor}</span>
                                                                            )}
                                                                        </div>

                                                                        <div className="input-group qty" style={{width: "50%", float: "right", maxWidth: "none"}}>
                                                                            <div data-objid={item.id} onClick={(e) => this.handleDecrease(e)} className="input-group-append">
                                                                                <span className="btn btn-default"> <i className="fa fa-minus"></i> </span>
                                                                            </div>
                                                                            <input type="text" className="form-control" value={item.quantity} readOnly style={{border: "none", textAlign: "center"}} />
                                                                            <div data-objid={item.id} data-max={item.max} onClick={(e) => this.handleIncrease(e)} className="input-group-prepend">
                                                                                <span className="btn btn-default">  <i className="fa fa-plus"></i> </span>
                                                                            </div> 
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            )
                                                        } )}
                                                        
                                                    </div>
                                                </div>
                                            
                                            )}

                                            {this.state.carrinho && (
                                                <div className="form-floating mb-15px">
                                                    <div>Alguma observação?</div>
                                                    <textarea className='form-control' name="complemento" onChange={(e) => this.handleComentario(e.target.value)}/>
                                                </div>
                                            )}
                                        </div>

                                        <div className="btn-row" style={{position:"absolute", height:"7%", bottom:"20px", width:"100%"}}>
                                            {this.state.brandData && this.state.brandData.loja.b_pedidos && this.state.carrinho && this.state.open? (
                                                <>
                                                    <Link to="/pos/customer-order" className="btn btn-default" style={{width:'40%', height: '100%', fontSize: "15px", paddingTop:'15px'}} onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>Fechar</Link>
                                                    {this.isProductValid() ?
                                                        <Link to="/pos/customer-order" className="btn btn-success" style={{width:'60%', height: '100%', fontSize: "15px", paddingTop:'15px'}} onClick={(event) => { event.preventDefault(); this.addCarrinho(this.state.produto)}}>Adicionar produto&nbsp; <i className="fa fa-plus fa-fw ml-2"></i></Link>
                                                    :
                                                        <div className="btn btn-success" style={{filter:'grayscale(1)', width:'60%', height: '100%', fontSize: "15px", paddingTop:'15px'}} >Adicionar produto&nbsp; <i className="fa fa-plus fa-fw ml-2"></i></div>
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {!this.state.open && (
                                                        <div className="alert alert-danger fade show" style={{ position: "absolute", bottom: "27px", width:"100%", textAlign:"center" }}>
                                                            Estamos fechados em este momento. :(
                                                        </div>
                                                    )}
                                                    <Link to="/pos/customer-order" style={{width:'100%', height:'100%', fontSize: "20px"}} className="btn btn-default" onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>Fechar</Link>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}