import React from 'react'
import { AppSettings } from '../../config/app-settings.js'

export default class CtaCompra extends React.Component {
    static contextType = AppSettings

    render() {
        return (
            <div className="pos-sidebar"  style={{top: "unset", display:"block", height: "100px", background: "#BFDDFF", position: "fixed", zIndex:"1000", padding:"20px",right: "0", bottom: '9%'}}>
                
                <span style={{float:'left'}}>
                    <span style={{fontSize:"12px",  color:"#2465AF"}}>SUBTOTAL</span> 
                    <br/>
                    <span style={{fontSize:"20px", color:"#09325C", fontWeight:"bold"}}>{this.context.itensCarrinho.reduce((a,item) => a += item.valorTotal * item.quantidade, 0 ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                </span>

                {this.context.open && (
                    <button style={{float:"right"}} className="btn btn-primary btn-lg" onClick={() => { this.props.handleCart()}}>
                        Ver carrinho
                    </button>
                )}
                
            </div>
        )
    }
}