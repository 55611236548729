import React from 'react'
import { AppSettings } from '../../config/app-settings.js'

import { Link } from 'react-router-dom'

import { getComanda, addPedido, getHistoricoCarrinho } from '../../store/api.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/pedido-enviado-cardapio.json"
import lottiesempedidos from "../../assets/pedidos-vazios-cozinha.json"

export default class SideBarDinein extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
        super(props)
        
        this.state = {
            abaHistorico:this.props.showHistorico ? this.props.showHistorico : false,
            historico:[]
        }

        this.handleAbaPedidos = this.handleAbaPedidos.bind(this)
        this.componentDidMount = this.componentDidMount.bind(this)
    }

    handleAbaPedidos(){
		this.setState({abaHistorico: !this.state.abaHistorico})
	}

    async componentDidMount(){
        const objMesa = this.context.mesaData
        if (objMesa) {
            this.setState({mesa: objMesa.data})
            this.props.loading(false)
        }
    }

    async enviaPedido(){
		const total = this.context.itensCarrinho.reduce((a,item) => a += item.valorTotal * item.quantidade, 0 ).toFixed(2)
		const comanda = this.context.comanda
		const data = { 
			valor: total, 
			itens: this.context.itensCarrinho, 
			id_comanda:comanda.id 
		}
		
		data.mesa = this.context.mesa

		const c = await getComanda({mesa:this.context.mesa})
		this.context.handleSetComanda(c.data)

		await addPedido(data)

		const historico = await getHistoricoCarrinho(comanda.id)
        this.context.sethistoricoCarrinho(historico.data)
        this.context.atualizaCarrinho([])
		this.setState({ pedidoEnviado: true})
	}

    mudaQuantidadeCarrinho(novoValor, index){
		const items = this.context.itensCarrinho

		if(novoValor === 'add') {
			items[index].quantidade = items[index].quantidade + 1 
		} else {
			items[index].quantidade = items[index].quantidade - 1 

			if (items[index].quantidade === 0 ) {
				items.splice(index, 1)
			}
		}

		this.context.atualizaCarrinho( items )
	}

    render() {
        const produtosHistorico = this.context.historicoCarrinho ? this.context.historicoCarrinho : []
        const itensCarrinho = this.context.itensCarrinho ? this.context.itensCarrinho : []
        const mesa = this.context.mesaData

        return (
            <div className="pos-sidebar" style={{height: "90%"}}>
                <div className="pos-sidebar-header" style={{backgroundColor: "#0078FF"}}>
                    <div className="title">
                        {mesa && mesa.b_comanda === 0 && 'Mesa '+ mesa.identificador }
                        {mesa && mesa.b_comanda === 1 && 'Comanda '+ mesa.identificador }
                    </div>
                    {this.state.pedido && (
                        <div className="order" style={{background:"none"}}>
                            Pedido: 
                            <b> {this.context.comanda} </b>
                        </div>
                    )}
                </div>

                <div className="pos-sidebar-nav">
                    <ul className="nav nav-tabs nav-fill">
                        <li className="nav-item">
                            <Link to="novo_pedido" onClick={(event) => { event.preventDefault(); this.handleAbaPedidos()}} className={this.state.abaHistorico ? "nav-link" : "nav-link active"}>
                                Carrinho ({itensCarrinho.length})
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="historico_pedido" onClick={(event) => { event.preventDefault(); this.handleAbaPedidos()}} className={this.state.abaHistorico ? "nav-link active" : "nav-link"}>
                                Meus pedidos ({produtosHistorico && produtosHistorico.pedidos ? produtosHistorico.pedidos.length : 0})
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="pos-sidebar-body tab-content" data-scrollbar="true" data-height="100%">
                    <div className={this.state.abaHistorico ? "tab-pane fade h-100 show" : "tab-pane fade h-100 show active"}  id="newOrderTab">
                        {itensCarrinho && itensCarrinho.length > 0 && (
                            <div className="pos-table">
                                {itensCarrinho.map((item,i) =>
                                    <div key={"item_"+i} className="row pos-table-row">
                                        <div className="col-9">
                                            <div className="pos-product-thumb">
                                                {item.imagem ? (
                                                    <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+item.imagem.imagem+")"}}></div>
                                                ) : (
                                                    <div className="img" style={{backgroundImage: "url(https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg)"}}></div>	
                                                )}
                                                <div className="info">
                                                    <div className="title">{item.nome.toUpperCase()}</div>
                                                    <div className="title" style={{fontSize:'12px'}}>
                                                        {item.variacao && item.variacao.length > 0 && (
                                                            item.variacao[0].nome
                                                        )}
                                                    </div>

                                                    <div className="single-price"> R$ {item.valor} </div>
                                                    
                                                    {item.opcionais && item.opcionais.length > 0 && (
                                                        item.opcionais.map(i => (
                                                            <div className="title" style={{fontSize:'12px'}}> Retirar {i.nome} </div>
                                                        ))
                                                    )}

                                                    {item.adicionais && item.adicionais.length > 0 && (
                                                        <>
                                                            {item.adicionais.map(i => (
                                                                <div className="title" style={{fontSize:'12px'}}>  
                                                                    {i.nome} {i.valor ? " - R$ " + i.valor : ""} 
                                                                </div>
                                                            ))}

                                                            <div className="single-price"> R$ { item.totalAdicional } </div>
                                                        </>
                                                    )}

                                                    <div className="input-group qty">
                                                        <div className="input-group-append">
                                                            <Link to="#" className="btn btn-default" onClick={() => this.mudaQuantidadeCarrinho('remove', i)}><i className="fa fa-minus"></i></Link>
                                                        </div>
                                                        <input type="text" className="form-control" value={item.quantidade} readOnly />
                                                        <div className="input-group-prepend">
                                                            <Link to="#" className="btn btn-default" onClick={() => this.mudaQuantidadeCarrinho('add', i)}><i className="fa fa-plus"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-3 total-price">{(parseFloat(item.valorTotal) * parseInt(item.quantidade)).toFixed(2)}</div>
                                    </div>
                                )}
                            </div>
                        )}


                        {(!itensCarrinho || (itensCarrinho && itensCarrinho.length === 0) ) && this.state.pedidoEnviado && (	
                            <div style={{padding:"15px", textAlign:"center"}}>
                                <Lottie animationData={lottiejson} loop={true} style={{width: "90%", margin:"auto"}} />
                                <h2 style={{textAlign:"center"}}>Pedido enviado <br></br>com sucesso :)</h2>
                                <p style={{textAlign:"center", fontSize:"15px"}}>Aguarde uns instantes <br />enquanto preparamos ele.</p>

                                <button type="button" className="btn btn-white" onClick={(event) => { event.preventDefault(); this.handleAbaPedidos()}}>
                                VER MEUS PEDIDOS
                                </button>
                            </div>
                        )}

                        {(!itensCarrinho || (itensCarrinho && itensCarrinho.length === 0) ) && !this.state.pedidoEnviado && (	
                            <div style={{padding:"15px", textAlign:"center"}}>
                                <Lottie animationData={lottiesempedidos} loop={true} style={{width: "80%", margin:"auto"}} />
                                <h2 style={{textAlign:"center"}}>Ainda não fez seu pedido? </h2>
                                <p style={{textAlign:"center"}}>Faça seu pedido agora clicando <br /> no produto e colocando ele no seu carrinho.</p>
                            </div>
                        )}
                    </div>

                    <div className={this.state.abaHistorico ? "tab-pane fade h-100 show active" : "tab-pane fade h-100 show "} id="history">
                        {this.context.comanda && (
                            <div className="pos-table" style={{padding:"0"}}>
                                {produtosHistorico && (
                                    <> 
                                        <address  style={{margin:"10px 20px", display:"block"}}>
                                            <strong className='h5 pt-2 mb-2'>Pedidos da minha mesa: {produtosHistorico.mesa && produtosHistorico.mesa.identificador}</strong>
                                        </address>

                                        {produtosHistorico.pedidos && produtosHistorico.pedidos.length > 0 && (
                                            <div style={{background:"#DEE2E6", padding:"0 20px"}}>
                                                {produtosHistorico.pedidos.map( p => p.produtos.map( (item,i) => 
                                                    <div key={"item_"+i} className="row pos-table-row">
                                                        <div className="col-9">
                                                            <div className="pos-product-thumb">
                                                                <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+item.imagem.imagem+")"}}></div>
                                                                <div className="info">
                                                                    <div className="title"><strong>{item.meta.pivot_quantidade} {item.nome.toUpperCase()} </strong></div>
                                                                    <div className="single-price text-gray">R$ {item.meta.pivot_valor_produto}</div>
                                                                </div>
                                                                <div className="title">
                                                                    {item.variacao && item.variacao.length > 0 && (
                                                                        item.variacao[0].nome
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 total-price"> R$ {(item.meta.pivot_valor_produto * item.meta.pivot_quantidade).toFixed(2)}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
               
                <div className="pos-sidebar-footer">
                    {this.state.abaHistorico && produtosHistorico && (
                        <div className="total">
                            <div className="text">Total</div>

                            {produtosHistorico.pedidos && produtosHistorico.pedidos.length > 0 && (
                                <div className="price">R$ { produtosHistorico.pedidos.reduce((a,item) => a += parseFloat( item.valor ), 0 ).toFixed(2) } </div>
                            )}
                        </div>
                    )}

                    {!this.state.abaHistorico && itensCarrinho.length > 0 && (
                        <div className="total">
                            <div className="text">Total</div>

                            {itensCarrinho && itensCarrinho.length > 0 && (
                                <div className="price">R$ { itensCarrinho.reduce((a,item) => a += parseFloat(item.valorTotal) * parseFloat(item.quantidade), 0 ).toFixed(2) } </div>
                            )}
                        </div>
                    )}

                    <div className="btn-row">
                        {!this.state.abaHistorico && itensCarrinho.length > 0 && (
                            <Link to="#" className="btn btn-success mb-3" onClick={(event) => { event.preventDefault(); this.enviaPedido(); }}>
                                <i className="fa fa-check fa-fw fa-lg"></i> Enviar Pedido
                            </Link>
                        )}
                    </div>
                    
                </div>
               
           </div>
        )
    }
}